var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
!function (e, t) {
  exports = t();
}("undefined" != typeof self ? self : exports, function () {
  return function (e) {
    var t = {};

    function o(n) {
      if (t[n]) return t[n].exports;
      var i = t[n] = {
        i: n,
        l: !1,
        exports: {}
      };
      return e[n].call(i.exports, i, i.exports, o), i.l = !0, i.exports;
    }

    return o.m = e, o.c = t, o.d = function (e, t, n) {
      o.o(e, t) || Object.defineProperty(e, t, {
        enumerable: !0,
        get: n
      });
    }, o.r = function (e) {
      "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
        value: "Module"
      }), Object.defineProperty(e, "__esModule", {
        value: !0
      });
    }, o.t = function (e, t) {
      if (1 & t && (e = o(e)), 8 & t) return e;
      if (4 & t && "object" == typeof e && e && e.__esModule) return e;
      var n = Object.create(null);
      if (o.r(n), Object.defineProperty(n, "default", {
        enumerable: !0,
        value: e
      }), 2 & t && "string" != typeof e) for (var i in e) o.d(n, i, function (t) {
        return e[t];
      }.bind(null, i));
      return n;
    }, o.n = function (e) {
      var t = e && e.__esModule ? function () {
        return e.default;
      } : function () {
        return e;
      };
      return o.d(t, "a", t), t;
    }, o.o = function (e, t) {
      return Object.prototype.hasOwnProperty.call(e, t);
    }, o.p = "", o(o.s = 0);
  }([function (e, t, o) {
    "use strict";

    (function (t) {
      function o(e, t) {
        if (null == e) return {};

        var o,
            n,
            i = function (e, t) {
          if (null == e) return {};
          var o,
              n,
              i = {},
              r = Object.keys(e);

          for (n = 0; n < r.length; n++) o = r[n], t.indexOf(o) >= 0 || (i[o] = e[o]);

          return i;
        }(e, t);

        if (Object.getOwnPropertySymbols) {
          var r = Object.getOwnPropertySymbols(e);

          for (n = 0; n < r.length; n++) o = r[n], t.indexOf(o) >= 0 || Object.prototype.propertyIsEnumerable.call(e, o) && (i[o] = e[o]);
        }

        return i;
      }

      var n = t.AudioContext || t.webkitAudioContext,
          i = function e() {
        var t = this || _global,
            o = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
        if (!e.isRecordingSupported()) throw new Error("Recording is not supported in this browser");
        (this || _global).state = "inactive", (this || _global).config = Object.assign({
          bufferLength: 4096,
          encoderApplication: 2049,
          encoderFrameSize: 20,
          encoderPath: "encoderWorker.min.js",
          encoderSampleRate: 48000,
          maxFramesPerPage: 40,
          mediaTrackConstraints: !0,
          monitorGain: 0,
          numberOfChannels: 1,
          recordingGain: 1,
          resampleQuality: 3,
          streamPages: !1,
          wavBitDepth: 16,
          sourceNode: {
            context: null
          }
        }, o), (this || _global).encodedSamplePosition = 0, this.initAudioContext(), (this || _global).initialize = this.initWorklet().then(function () {
          return t.initEncoder();
        });
      };

      i.isRecordingSupported = function () {
        var e = t.navigator && t.navigator.mediaDevices && t.navigator.mediaDevices.getUserMedia;
        return n && e && t.WebAssembly;
      }, i.version = "8.0.5", i.prototype.clearStream = function () {
        (this || _global).stream && ((this || _global).stream.getTracks ? (this || _global).stream.getTracks().forEach(function (e) {
          return e.stop();
        }) : (this || _global).stream.stop());
      }, i.prototype.close = function () {
        return (this || _global).monitorGainNode.disconnect(), (this || _global).recordingGainNode.disconnect(), (this || _global).sourceNode && (this || _global).sourceNode.disconnect(), this.clearStream(), (this || _global).encoder && ((this || _global).encoderNode.disconnect(), (this || _global).encoder.postMessage({
          command: "close"
        })), (this || _global).config.sourceNode.context ? Promise.resolve() : (this || _global).audioContext.close();
      }, i.prototype.encodeBuffers = function (e) {
        if ("recording" === (this || _global).state) {
          for (var t = [], o = 0; o < e.numberOfChannels; o++) t[o] = e.getChannelData(o);

          (this || _global).encoder.postMessage({
            command: "encode",
            buffers: t
          });
        }
      }, i.prototype.initAudioContext = function () {
        (this || _global).audioContext = (this || _global).config.sourceNode.context ? (this || _global).config.sourceNode.context : new n(), (this || _global).monitorGainNode = (this || _global).audioContext.createGain(), this.setMonitorGain((this || _global).config.monitorGain), (this || _global).recordingGainNode = (this || _global).audioContext.createGain(), this.setRecordingGain((this || _global).config.recordingGain);
      }, i.prototype.initEncoder = function () {
        var e = this || _global;
        (this || _global).audioContext.audioWorklet ? ((this || _global).encoderNode = new AudioWorkletNode((this || _global).audioContext, "encoder-worklet", {
          numberOfOutputs: 0
        }), (this || _global).encoder = (this || _global).encoderNode.port) : (console.log("audioWorklet support not detected. Falling back to scriptProcessor"), (this || _global).encodeBuffers = function () {
          return delete e.encodeBuffers;
        }, (this || _global).encoderNode = (this || _global).audioContext.createScriptProcessor((this || _global).config.bufferLength, (this || _global).config.numberOfChannels, (this || _global).config.numberOfChannels), (this || _global).encoderNode.onaudioprocess = function (t) {
          var o = t.inputBuffer;
          return e.encodeBuffers(o);
        }, (this || _global).encoderNode.connect((this || _global).audioContext.destination), (this || _global).encoder = new t.Worker((this || _global).config.encoderPath));
      }, i.prototype.initSourceNode = function () {
        var e = this || _global;
        return (this || _global).config.sourceNode.context ? ((this || _global).sourceNode = (this || _global).config.sourceNode, Promise.resolve()) : t.navigator.mediaDevices.getUserMedia({
          audio: (this || _global).config.mediaTrackConstraints
        }).then(function (t) {
          e.stream = t, e.sourceNode = e.audioContext.createMediaStreamSource(t);
        });
      }, i.prototype.initWorker = function () {
        var e = this || _global,
            t = ((this || _global).config.streamPages ? (this || _global).streamPage : (this || _global).storePage).bind(this || _global);
        return (this || _global).recordedPages = [], (this || _global).totalLength = 0, new Promise(function (n) {
          e.encoder.addEventListener("message", function o(i) {
            var r = i.data;

            switch (r.message) {
              case "ready":
                n();
                break;

              case "page":
                e.encodedSamplePosition = r.samplePosition, t(r.page);
                break;

              case "done":
                e.encoder.removeEventListener("message", o), e.finish();
            }
          }), e.encoder.start && e.encoder.start();
          var i = e.config,
              r = (i.sourceNode, o(i, ["sourceNode"]));
          e.encoder.postMessage(Object.assign({
            command: "init",
            originalSampleRate: e.audioContext.sampleRate,
            wavSampleRate: e.audioContext.sampleRate
          }, r));
        });
      }, i.prototype.initWorklet = function () {
        return (this || _global).audioContext.audioWorklet ? (this || _global).audioContext.audioWorklet.addModule((this || _global).config.encoderPath) : Promise.resolve();
      }, i.prototype.pause = function (e) {
        var t = this || _global;
        if ("recording" === (this || _global).state) return (this || _global).state = "paused", (this || _global).recordingGainNode.disconnect(), e && (this || _global).config.streamPages ? new Promise(function (e) {
          t.encoder.addEventListener("message", function o(n) {
            "flushed" === n.data.message && (t.encoder.removeEventListener("message", o), t.onpause(), e());
          }), t.encoder.start && t.encoder.start(), t.encoder.postMessage({
            command: "flush"
          });
        }) : (this.onpause(), Promise.resolve());
      }, i.prototype.resume = function () {
        "paused" === (this || _global).state && ((this || _global).state = "recording", (this || _global).recordingGainNode.connect((this || _global).encoderNode), this.onresume());
      }, i.prototype.setRecordingGain = function (e) {
        (this || _global).config.recordingGain = e, (this || _global).recordingGainNode && (this || _global).audioContext && (this || _global).recordingGainNode.gain.setTargetAtTime(e, (this || _global).audioContext.currentTime, 0.01);
      }, i.prototype.setMonitorGain = function (e) {
        (this || _global).config.monitorGain = e, (this || _global).monitorGainNode && (this || _global).audioContext && (this || _global).monitorGainNode.gain.setTargetAtTime(e, (this || _global).audioContext.currentTime, 0.01);
      }, i.prototype.start = function () {
        var e = this || _global;
        return "inactive" === (this || _global).state ? ((this || _global).state = "loading", (this || _global).encodedSamplePosition = 0, (this || _global).audioContext.resume().then(function () {
          return e.initialize;
        }).then(function () {
          return Promise.all([e.initSourceNode(), e.initWorker()]);
        }).then(function () {
          e.state = "recording", e.encoder.postMessage({
            command: "getHeaderPages"
          }), e.sourceNode.connect(e.monitorGainNode), e.sourceNode.connect(e.recordingGainNode), e.monitorGainNode.connect(e.audioContext.destination), e.recordingGainNode.connect(e.encoderNode), e.onstart();
        }).catch(function (t) {
          throw e.state = "inactive", t;
        })) : Promise.resolve();
      }, i.prototype.stop = function () {
        var e = this || _global;
        return "paused" === (this || _global).state || "recording" === (this || _global).state ? ((this || _global).state = "inactive", (this || _global).recordingGainNode.connect((this || _global).encoderNode), (this || _global).monitorGainNode.disconnect(), this.clearStream(), new Promise(function (t) {
          e.encoder.addEventListener("message", function o(n) {
            "done" === n.data.message && (e.encoder.removeEventListener("message", o), t());
          }), e.encoder.start && e.encoder.start(), e.encoder.postMessage({
            command: "done"
          });
        })) : Promise.resolve();
      }, i.prototype.storePage = function (e) {
        (this || _global).recordedPages.push(e), (this || _global).totalLength += e.length;
      }, i.prototype.streamPage = function (e) {
        this.ondataavailable(e);
      }, i.prototype.finish = function () {
        if (!(this || _global).config.streamPages) {
          var e = new Uint8Array((this || _global).totalLength);
          (this || _global).recordedPages.reduce(function (t, o) {
            return e.set(o, t), t + o.length;
          }, 0), this.ondataavailable(e);
        }

        this.onstop();
      }, i.prototype.ondataavailable = function () {}, i.prototype.onpause = function () {}, i.prototype.onresume = function () {}, i.prototype.onstart = function () {}, i.prototype.onstop = function () {}, e.exports = i;
    }).call(this || _global, o(1));
  }, function (e, t) {
    var o;

    o = function () {
      return this || _global;
    }();

    try {
      o = o || new Function("return this")();
    } catch (e) {
      "object" == typeof window && (o = window);
    }

    e.exports = o;
  }]);
});
export default exports;
export const Recorder = exports.Recorder;